import axios from './instance'

const configToken = (token) => {
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    // editar servicios
export const getAllSales = async(token, page, q, dates, search) => {
    try {
        // filtros resueltos en caso de necesitarlos descomentar y adaptar
        let URL = `/sales?perPage=30&page=${page}`
        if (dates !== undefined && dates !== '') { URL += `&betweendates=${dates}` }
        if (q !== undefined && q !== '') { URL += `&q=${q}` }
        if (search !== undefined && search !== '') { URL += `&search=${search}` }
        /* const URL = '/sales' */
        const res = await axios.get(URL, configToken(token))
            /* const res = await axios.get('/sales', configToken(token)) */
        return res
    } catch (err) {
        throw err.response
    }
}

export const getSalesComplete = async(token, from, to, q) => {
    try {
        const res = await axios.get(`/sales-all?date_from=${from}&date_to=${to}&search=${q}`, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const getSale = async(sale) => {
    try {
        const res = await axios.get(`/sale/${sale}`)
        return res
    } catch (err) {
        throw err.response
    }
}

export const createSale = async(data, token) => {
        try {
            const res = await axios.post('/sales', data, configToken(token))
            return res
        } catch (err) {
            console.error('error', err)
            throw err.response
        }
    }
    // revisar si esta ok
export const updateSale = async(id, data, token) => {
    try {
        const res = await axios.put('/sales/' + id, data, configToken(token))
        return res
    } catch (err) {
        console.error('error', err)
        throw err.response
    }
}