<template>
    <td :colspan="headers.length">
        <div class="grid-container">
            <div class="grid">
                <span
                    v-for="(i, index) in expandHeaders"
                    :class="`caption text-secondary text-${i.align}`"
                    :key="index"
                    >{{ i.text }}</span
                >
            </div>

            <div
                class="text-left grid"
                v-for="(element, index) in item.products"
                :key="index"
            >
                <span
                    v-for="(i, index) in expandHeaders"
                    :key="index"
                    :class="`caption text-secondary text-${i.align}`"
                >
                    <span
                        style="
                            font-family: Roboto;
                            font-size: 0.75rem;
                            font-weight: 400;
                        "
                    >
                        {{ element[i.value] }}
                    </span>
                </span>
            </div>
        </div>
    </td>
</template>

<script>
export default {
    name: "SaleExpandedItem",
    props: {
        item: Object,
        headers: Array,
        expandHeaders: {
            type: Array,
            default() {
                return [
                    {
                        text: "SKU",
                        value: "product_name",
                        align: "left",
                    },
                    {
                        text: "Nombre",
                        value: "name",
                        align: "left",
                    },
                    {
                        text: "Cantidad",
                        value: "character",
                        align: "left",
                    },
                    {
                        text: "Precio unitario",
                        value: "color",
                        align: "left",
                    },
                    {
                        text: "Total",
                        value: "quantity",
                        align: "right",
                    },
                ];
            },
        },
        isSaleTable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.border-dashed {
    width: 100%;
    height: 2px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E0E0FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 10px 0;
}
</style>
