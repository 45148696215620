<template>
    <div>
        <FilterComponent
            v-if="tableFilters"
            :isUserAdmin="isUserAdmin"
            @filter="filter"
            @clearFilters="clearFilters"
        />
        <DownloadComponent :dataTable="dataTable" v-if="download" />
        <v-simple-table :height="scrollHeight">
            <template v-slot:default>
                <AppTable
                    :showExpand="showExpand"
                    :details="details"
                    :dataTable="dataTable"
                    :headers="headers"
                    :getColor="getColor"
                    :elevation="'elevation-1'"
                    :expanded="expanded"
                    :currentPage="paginationOptions.page"
                    :options.sync="tableOptions"
                    :pagination.sync="paginationOptions"
                    @handleExpandedItem="handleExpandedItem"
                    @updateOptions="updateTable"
                    @handleState="handleSelectedMenuItem"
                    @getMainSale="getMainSale"
                >
                    <template v-slot:insideTable>
                        <ToolbarInsideTable
                            :dialog="dialog"
                            :openNewItem="openNewItem"
                            :tableTitle="setTableTitle"
                            :toolbarBtnText="setBtnNewItemText"
                            @handleNewItem="handleNewItem"
                        >
                            <template v-slot:newItem>
                                <NewItem
                                    :openNewItem="openNewItem"
                                    :editedItem="editedItem"
                                    :isSale="true"
                                    :NewItemTitle="setNewItemTitle"
                                    :NewItemInputValues="NewItemInputValues"
                                    :NewItemInputSelect="NewItemInputSelect"
                                    :NewItemInputValuesSales="
                                        NewItemInputValuesSales
                                    "
                                    :loadingSave="loadingSave"
                                    :validationsProps="validationsProps"
                                    :errorsValidationProps="
                                        errorsValidationProps
                                    "
                                    :isEditing="isEditing"
                                    @clearEditedItemProduct="
                                        clearEditedItemProduct
                                    "
                                    @close="close"
                                    @save="save"
                                    @removeItem="removeItem"
                                />
                            </template>
                        </ToolbarInsideTable>
                    </template>
                    <template v-slot:paginationTable>
                        <Pagination
                            :pagination="paginationOptions"
                            :options.sync="paginationOptions"
                            :isSales="true"
                            @updateOptions="updateTable"
                        />
                    </template>

                    <!-- <template v-slot:iconInsideColumn="slotProps">
        <component :is="setIcon(slotProps.item.id_sales_channel)" class="mr-4"/>
      </template> -->

                    <template v-slot:columnExpandTable="slotProps">
                        <ColumnExpand
                            :item="slotProps.item"
                            :isExpanded="slotProps.isExpanded"
                            :expand="slotProps.expand"
                            :selectedMenuItem="selectedMenuItem"
                            :itemsMenuList="itemsMenuList"
                            @handleSelectedMenuItem="handleSelectedMenuItem"
                        />
                    </template>
                    <template
                        v-slot:expandedItemTable="slotProps"
                        v-if="resumen"
                    >
                        <ExpandedItem
                            :isSaleTable="true"
                            :item="slotProps.item"
                            :headers="slotProps.headers"
                            :expandHeaders="expandHeaders"
                        />
                    </template>
                </AppTable>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import AppTable from "@/components/views/expandTable/AppTable.vue";
import Pagination from "@/components/views/expandTable/tableComponents/Pagination.vue";
import ColumnExpand from "@/components/views/expandTable/tableComponents/ColumnExpand.vue";
import ExpandedItem from "@/components/views/expandTable/tableComponents/SaleExpandedItem.vue";
import ToolbarInsideTable from "@/components/views/dataTableOne/layoutTwo/toolbar/ToolbarInsideTable.vue";
import NewItem from "@/components/views/dataTableOne/layoutTwo/newItem/NewItem.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { createSale, updateSale, getAllSales } from "@/services/sales.js";
import { getAllProducts } from "@/services/products.js";
import { getAllUsers } from "@/services/users";
import { required } from "vuelidate/lib/validators";

import FilterComponent from "./Filter.vue";
import DownloadComponent from "./DownloadComponent.vue";
import { EventBus } from "@/Event-bus.js";
import moment from "moment";

moment.locale("es-AR");

export default {
    name: "SalesTable",
    components: {
        AppTable,
        Pagination,
        ColumnExpand,
        ExpandedItem,
        FilterComponent,
        DownloadComponent,
        ToolbarInsideTable,
        NewItem,
    },
    props: {
        isUserAdmin: Boolean,
        filterStartAndEndDate: {
            type: Array,
            default: () => [],
        },
        isClearingDates: {
            type: Boolean,
            required: false,
            default: false,
        },
        resumen: {
            type: Boolean,
            default: true,
        },
        download: {
            type: Boolean,
            default: true,
        },
        scrollHeight: {
            type: Number,
            default: 1500,
        },
        dialog: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Boolean,
            default: true,
        },
        showExpand: {
            type: Boolean,
            default: true,
        },
        tableFilters: {
            type: Boolean,
            default: true,
        },
        productName: {
            type: String,
            default: "",
            required: false,
        },
        headers: {
            type: Array,
            default: () => [
                { text: "Nro. venta", value: "id", align: "left" },
                { text: "Fecha venta", value: "date_created", align: "left" },
                { text: "Usuario", value: "user", align: "left" },
                {
                    text: "Forma de Pago",
                    value: "payment_method",
                    align: "left",
                },
                { text: "Total", value: "total_amount", align: "left" },
                // { text: '', value: 'data-table-preview', align: 'right' },
                { text: "", value: "data-table-expand", align: "right" },
            ],
        },
    },
    data: () => ({
        isEditing: false,
        openNewItem: false,
        loadingSave: false,
        expanded: [],
        products: [],
        singleExpand: false,
        selectedMenuItem: null,
        itemsMenuList: [
            { text: "Editar", fn: "editItem" },
            /* { text: 'Cambiar Estado', fn: 'showChangeStatus' },
      { text: 'Vista Previa', fn: 'showPreviewData' },
      { text: 'Copiar Enlace', fn: 'copyLink' },
      { text: 'Comentario Interno', fn: 'showDialogCommentary' }
      { text: 'Modificar Datos Cliente', fn: 'showChangeClientData' },
      { text: 'Imprimir etiqueta e-Flet', fn: 'printTicketEflet' } */
        ],
        expandHeaders: [
            { text: "SKU", value: "sku", align: "left" },
            { text: "Nombre", value: "name", align: "left" },
            { text: "Cantidad", value: "quantity", align: "left" },
            {
                text: "Precio unitario",
                value: "total_amount",
                align: "left",
            },
            { text: "Total", value: "total_amount", align: "left" },
        ],
        NewItemInputValues: [
            // { text: 'Sku', value: 'sku', align: 'left' },
            {
                text: "Producto",
                value: "products",
                type: "autocomplete",
                align: "left",
                col: 6,
            },
            {
                text: "% de Bonificación",
                value: "bonification_percentage",
                type: "hidden",
            },
            { text: "Cantidad", value: "quantity", align: "left", col: 2 },
            { text: "Precio", value: "price", type: "hidden" },
            { text: "Total", value: "total", type: "hidden" },
        ],
        NewItemInputValuesSales: [
            {
                text: "Forma de pago",
                value: "paymentMethod",
                type: "select",
                fromSale: true,
                align: "left",
                col: 12,
            },
        ],
        NewItemInputSelect: {
            products: [],
            paymentMethod: [
                { text: "Efectivo", value: 1 },
                { text: "Dinero en cuenta MP", value: 2 },
                { text: "Débito", value: 3 },
                { text: "Credito", value: 4 },
                { text: "Transferencia", value: 5 },
            ],
        },
        validationsProps: {
            editedItem: {
                products: {},
                quantity: {},
                bonification_percentage: {},
                price: {},
                total: {},
                paymentMethod: { required },
            },
        },
        /* setNewItemInputSelect (data, key, val = 'name') {
      const array = []
      data.forEach(e => {
        const obj = { text: e[val], value: e.id }
        array.push(obj)
      })
      this.NewItemInputSelect[key] = array
    },
    async getRoles () {
      try {
        const res = await getAllRoles(this.userToken)
        this.setNewItemInputSelect(res.data, 'role')
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    }, */
        dataTable: [],
        tableOptions: {
            page: 1,
            itemsPerPage: 30,
        },
        paginationOptions: {
            page: 1,
            itemsPerPage: 30,
            pageStart: 0,
            pageStop: 30,
            itemsLength: 30,
        },
        search: "",
        filters: {
            date: "",
            status: "",
            user: "",
        },
        allSales: [],
        allFiltered: [],
        usersItems: [],
        editedItem: {},
        defaultItem: {},
        editedIndex: -1,
        showPreview: null,
        showDialog: false,
        showDialogUser: false,
        showDialogComentary: false,
        // currentPage: 0
    }),
    validations() {
        return this.validationsProps;
    },
    computed: {
        ...mapState(["userToken", "textSearch"]),
        ...mapGetters([
            "userData",
            "getTextSearch",
            "getSalesFilters",
            "getUser",
            "getUserDb",
        ]),
    },

    watch: {
        openNewItem(val) {
            val || this.close();
        },
        /* textSearch (newValue) {
      const searchRegex = new RegExp(newValue, 'i')
      this.dataTable = this.allFiltered.filter((event) => {
        if (!newValue || searchRegex.test(event.id_external) || searchRegex.test(event.email)) return event

        if (event.products_order && event.products_order.length > 0) {
          for (let i = 0; i < event.products_order.length; i++) {
            if (searchRegex.test(event.products_order[i].name) || searchRegex.test(event.products_order[i].last_name)) return event
          }
        }
      })
    }, */
        paginationOptions: {
            handler() {
                this.tableOptions.page = this.paginationOptions.page;
            },
        },
        isClearingDates: {
            async handler(newValue, oldValue) {
                if (newValue === true) {
                    await this.clearFilters();
                }
            },
        },
        filterStartAndEndDate: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                if (newValue.length !== 0) {
                    await this.filter();
                }
            },
        },
        productName: {
            immediate: true,
            async handler(newValue, oldValue) {
                if (newValue.length !== 0) {
                    await this.filter();
                }
            },
        },
    },

    async created() {
        this.filters = this.getSalesFilters.date;
        this.search = this.getTextSearch;
        EventBus.$on("doSearch", async () => {
            this.search = this.getTextSearch;
            this.handleLoading(true);
            await this.getSales(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            this.handleLoading(false);
        });
        if (this.$route.path.includes("sales")) {
            await this.initialize();
        }
    },

    methods: {
        ...mapActions([
            "signout",
            "handleLoading",
            "handleAlert",
            "handleSalesFilters",
        ]),
        async save(products, editedItem) {
            if (this.editedIndex > -1) {
                await this.updateOne(products, editedItem);
            } else {
                await this.createOne(products, editedItem.paymentMethod);
            }
        },
        async createOne(products, paymentMethod) {
            try {
                this.loadingSave = true;
                const data = this.formatProductsForDb(products, paymentMethod);
                const res = await createSale(data, this.userToken);
                this.handleMessageSuccessApi(res.data.message);
                this.dataTable.push(this.formatDataTable(res.data.new_product));
                this.dataTable = this.sortSales(this.dataTable);
                this.close();
            } catch (error) {
                console.error("error", error);
                this.handleMessageErrorApi(error);
            } finally {
                this.loadingSave = false;
            }
        },
        // update pendiente
        async updateOne(products, editedItem) {
            try {
                this.loadingSave = true;
                const data = this.formatProductsForDb(
                    products,
                    editedItem.paymentMethod
                );
                const res = await updateSale(
                    this.editedItem.id,
                    data,
                    this.userToken
                );
                this.handleMessageSuccessApi(res.data.message);
                Object.assign(
                    this.dataTable[this.editedIndex],
                    this.formatDataTable(res.data.new_product)
                );
                this.close();
            } catch (error) {
                console.log(error.response);
                this.handleMessageErrorApi(error);
            } finally {
                this.loadingSave = false;
            }
        },
        async initialize() {
            this.handleLoading(true);

            await this.getSales(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            const res = await getAllProducts(this.userToken);
            this.fillProductsSelect(res.data);
            // await this.setPage()
            this.handleLoading(false);
        },
        fillProductsSelect(arr) {
            if (arr) {
                arr.forEach((p) => {
                    if (p.stock > 0) {
                        this.NewItemInputSelect.products.push({
                            value: p.id,
                            text: p.name,
                            actual_price: p.actual_price,
                            prices: p.prices,
                            sku: p.sku,
                            stock: p.stock,
                        });
                    }
                });
                // ORDENO ALFABETICAMENTE
                this.NewItemInputSelect.products =
                    this.NewItemInputSelect.products.sort((a, b) => {
                        if (a.text < b.text) {
                            return -1;
                        }
                        if (a.text > b.text) {
                            return 1;
                        }
                        return 0;
                    });
            }
        },
        async getSales(page, q, dates) {
            try {
                const res = await getAllSales(
                    this.userToken,
                    page,
                    q,
                    dates?.length ? dates : this.getSalesFilters.date,
                    this.productName
                );
                this.allSales = await this.formatDataTable(res.data.data);
                this.dataTable = this.allSales;
                this.dataTable = this.sortSales(this.dataTable);
                this.updatePagination(
                    page,
                    res.data.total,
                    res.data.from,
                    res.data.to
                );
            } catch (error) {
                console.error("error", error);
                this.handleMessageErrorApi(error);
            }
        },
        sortSales(sales) {
            return sales.sort((a, b) => b.id - a.id);
        },
        async getUsers() {
            try {
                this.handleLoading(true);
                const res = await getAllUsers(this.userToken);
                this.usersItems = this.formatUsersItems(res.data);
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },
        formatDataTable(data) {
            if (Array.isArray(data)) {
                data.forEach((e) => {
                    this.assignData(e);
                });
            } else {
                this.assignData(data);
            }
            return data;
        },
        assignData(e) {
            e.user = e.user?.name + " " + e.user?.last_name;
            e.payment_method = e.payment_method.name;
            e.total_amount = "$" + e.total_amount;
            e.applyBonification = e.bonification_percentage !== 0;
            e.date_created = e.created_at.substr(0, 10);
            e.date_created = moment(e.created_at).format("LL");
            e.products.forEach((p) => {
                p.quantity = p.pivot.quantity;
                p.price = p.actual_price;
                p.total_amount = p.pivot.quantity * p.pivot.unit_price;
            });
        },
        formatUsersItems(data) {
            const array = [{ value: 0, text: "Sin usuario asignado" }];
            data.forEach((u) => {
                array.push({
                    value: u.id,
                    text: u.name + " " + u.last_name + " - " + u.roles[0].name,
                });
            });
            return array;
        },
        formatProductsForDb(products, paymentMethodId) {
            const data = {};
            const formattedProducts = [];
            products.forEach((p) =>
                formattedProducts.push({
                    id: p.products || p.id,
                    unit_price: p.price,
                    quantity: p.quantity,
                })
            );
            data.user_id = this.getUserDb.id;
            data.payment_method_id = paymentMethodId;
            data.products = formattedProducts;
            data.bonification_percentage =
                this.editedItem.bonification_percentage !== 0 &&
                this.editedItem.bonification_percentage !== "" &&
                this.editedItem.applyBonification
                    ? this.editedItem.bonification_percentage
                    : 0;
            return data;
        },
        setTableTitle() {
            return "Lista de Ventas";
        },
        setBtnNewItemText() {
            return "Nuevo";
        },
        setNewItemTitle() {
            return this.editedIndex === -1 ? "Crear Venta" : "Editar Venta";
        },
        handleNewItem(value) {
            this.openNewItem = value;
        },
        editItem(item) {
            this.$nextTick(() => {
                this.editedIndex = this.dataTable.indexOf(item.itemPreview);
                this.editedItem = Object.assign({}, item.itemPreview);
                this.editedItem.paymentMethod =
                    this.editedItem.payment_method_id;
                this.openNewItem = true;
                this.isEditing = true;
            });
        },
        clearEditedItemProduct() {
            const payment = this.editedItem.paymentMethod;
            this.editedItem = Object.assign({}, this.defaultItem);
            if (payment !== undefined) this.editedItem.paymentMethod = payment;
        },
        close() {
            this.openNewItem = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.isEditing = false;
            });
        },
        handleSelectedMenuItem(val) {
            this.editedItem = val.itemPreview;
            this.editedIndex = this.dataTable.indexOf(val.itemPreview);
            this[val.item.fn](val); // llamo a la funcion de cada item seteada
        },
        showChangeStatus() {
            this.showDialog = true;
        },
        showPreviewData() {
            this.$router.push({ path: `/preview/${this.editedItem.id}` });
        },
        copyLink() {
            let baseurl = null;
            if (process.env.VUE_APP_API_URL_MODE === "development") {
                baseurl = process.env.VUE_APP_ADMIN_URL_DEV;
            } else {
                baseurl = process.env.VUE_APP_ADMIN_URL_PROD;
            }
            var aux = document.createElement("input");
            aux.setAttribute("value", `${baseurl}/order/${this.editedItem.id}`);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            this.handleMessageSuccessApi("Enlace copiado al portapapeles");
        },
        showDialogCommentary() {
            this.showDialogComentary = true;
        },
        showChangeClientData() {
            this.showDialogUser = true;
        },
        errorsValidationProps(value, item) {
            if (!item.required) return true;
        },
        /* setPage () {
      if (localStorage.getItem('currentPageSales')) {
        this.currentPage = Number(localStorage.getItem('currentPageSales'))
      } else {
        this.currentPage = 1
      }
    }, */

        async updateTable(paginationData) {
            this.handleLoading(true);
            await this.getSales(
                paginationData.page,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            this.handleLoading(false);
        },

        async getMainSale(sale) {
            /* this.handleLoading(true)
      await this.getSales(1, order, this.filters.date, this.filters.status, this.filters.user)
      await this.handleExpandedItem()
      // await this.setPage()
      // localStorage.setItem('currentPageSales', page.page)
      this.handleLoading(false) */
            EventBus.$emit("changeTextSearch", sale);
        },

        updatePagination(page, lastPage, pageStart, pageStop) {
            this.paginationOptions.page = page;
            this.paginationOptions.itemsLength = lastPage;
            this.paginationOptions.pageStart = pageStart - 1;
            this.paginationOptions.pageStop = pageStop;
        },

        handleExpandedItem(val) {
            this.expanded = this.allSales;
        },

        handleMessageSuccessApi(message) {
            this.alertColor = "success";
            this.alertMessage = message;
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },

        handleMessageErrorApi(error) {
            this.alertColor = "error";
            if (error.status === 401) {
                this.signout();
                this.alertMessage = "Sesión caducada";
            } else {
                if (error.status === 422) {
                    const firstError = Object.keys(error.data.errors)[0];
                    this.alertMessage = error.data.errors[firstError][0];
                } else {
                    this.alertMessage = error.status;
                }
            }
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },
        // borrar en caso de que no se usen otras funciones. Viene de sales table
        /* async updateSale (params) {
      try {
        this.handleLoading(true)
        if (params.dialog === 'state') {
          const res = await updateStatus(params.order, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        } else if (params.dialog === 'user') {
          const data = await this.formatForUpdateUser(params.order)
          const res = await updateUser(params.order.client.id, data, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        } else if (params.dialog === 'commentary') {
          const res = await updateCommentary(params.order, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        } else if (params.dialog === 'assignUser') {
          const res = await asignUser(params.order.id_user, params.order.id, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        }
        this.closeDialog(params.dialog)
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    }, */
        formatForUpdateUser(data) {
            return {
                name: data.client.name,
                last_name: data.client.last_name,
                email: data.client.email,
                client_address: data.client_address,
                client_city: data.client_city,
                client_province: data.client_Province,
                client_postcode: data.client_postcode,
                id_order: data.id,
            };
        },
        closeDialog(dialog) {
            dialog === "state"
                ? (this.showDialog = false)
                : dialog === "user"
                ? (this.showDialogUser = false)
                : (this.showDialogComentary = false);
        },

        getColor(value) {
            let result = "";
            if (value.state.id === 5) {
                result =
                    value.shipping.includes("Retiro oficina") ||
                    value.shipping.includes("Retiro por local")
                        ? "Pedido listo para retirar"
                        : "Pedido listo para despachar";
            } else {
                result = value.state.name;
            }
            const colors = {
                Aprobado: "rgba(232, 0, 237, 1)",
                "En produccion": "rgba(255, 114, 0, 0.35)",
                "Pedido listo para retirar": "rgba(0, 237, 137, 1)",
                "Pedido listo para despachar": "rgba(0, 150, 137, 1)",
                "Pedido casi listo": "rgba(142, 0, 237)",
                Cancelado: "rgba(237, 71, 0, 1)",
            };
            return colors[result] || "rgba(255, 114, 0, 0.35)";
        },

        setIcon(value) {
            const icon = {
                Meli: "MeliIcon",
                Woo: "WooIcon",
            };
            value = value === 2 ? "Woo" : "Meli";
            return icon[value] || "div";
        },

        async filter() {
            this.handleLoading(true);
            this.filters = this.getSalesFilters.date;
            /* this.getSalesFilters.date.status === 0
        ? this.filters.status = ''
        : this.filters.status = this.getSalesFilters.date.status
      this.getSalesFilters.date.date.length === 0
        ? this.filters.date = ''
        : this.filters.date = this.getSalesFilters.date.date.replace(' - ', ',')
      this.getSalesFilters.date.user === 0
        ? this.filters.user = ''
        : this.filters.user = this.getSalesFilters.date.user */
            const date = this.$route.path.includes("sales")
                ? this.filters.date
                : this.filterStartAndEndDate;

            await this.getSales(
                1,
                this.search,
                date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            // this.dataTable = this.allFiltered
            this.handleLoading(false);
        },

        async clearFilters() {
            let currentDate = new Date();
            let firstDayOfCurrentMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );

            let currentDateStr = currentDate.toISOString().slice(0, 10);
            let firstDayOfCurrentMonthStr = firstDayOfCurrentMonth
                .toISOString()
                .slice(0, 10);

            this.filterStartAndEndDate = [
                firstDayOfCurrentMonthStr,
                currentDateStr,
            ];
            this.handleLoading(true);
            this.filters.date = "";
            this.filters.status = 0;
            this.filters.user = 0;
            const date = this.$route.path.includes("sales")
                ? this.filters.date
                : this.filterStartAndEndDate;
            this.handleSalesFilters({ date: "", status: 0, user: 0 });
            await this.getSales(1, this.search, date);
            await this.handleExpandedItem();
            this.handleLoading(false);
        },
        removeItem(item) {},

        /* filterState (params, row) {
      if (params.state) {
        if (row.order_status.id === params.state) return true
      } else {
        return true
      }
    } */
    },
};
</script>
