<template>
    <v-card class="mb-3" elevation="0">
        <v-row align="center" style="height: 100%">
            <v-col class="" align-self="center">
                <v-row class="pl-4" justify="start">
                    <v-menu
                        :class="{ 'col-4': isUserAdmin }"
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateRangeText"
                                :label="inputLabel"
                                @click="inputLabel = 'Rango de fechas'"
                                :placeholder="
                                    $route.path.includes('dashboard')
                                        ? defaultDate[0] +
                                          ' - ' +
                                          defaultDate[1]
                                        : false
                                "
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title range scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-select
                        v-if="isOrders"
                        class="pl-3"
                        :class="{ 'col-4': isUserAdmin }"
                        label="Seleccionar estado"
                        v-model="status"
                        :items="statusItems"
                        item-text="name"
                        item-value="id"
                        hide-details
                    >
                    </v-select>
                    <v-select
                        v-if="isUserAdmin && isOrders"
                        class="pl-3"
                        :class="{ 'col-4': isUserAdmin }"
                        label="Seleccionar usuario"
                        v-model="user"
                        :items="userItems"
                        item-text="name"
                        item-value="id"
                        hide-details
                    >
                    </v-select>
                </v-row>
            </v-col>
            <v-col cols="2" v-if="$route.name === 'Dashboard'">
                <TextField
                    placeholder="Nombre del producto"
                    v-model="productName"
                />
            </v-col>
            <v-col
                :class="{ 'col-3': isUserAdmin }"
                align-self="center"
                style="text-align: left"
            >
                <v-btn
                    text
                    :color="'white'"
                    plain
                    style="background-color: #eda6ad"
                    @click="filterArray"
                >
                    Filtrar
                </v-btn>
                <v-btn
                    text
                    plain
                    class="font-weight-regular"
                    :color="'#EDA6AD'"
                    @click="clearFilters"
                >
                    Limpiar
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { getAllStatus } from "@/services/status.js";
import { getAllUsers } from "@/services/users.js";
import moment from "moment";
import TextField from "@/components/vuetify/TextField";

export default {
    components: {
        TextField,
    },
    data: () => ({
        status: 0,
        date: [moment().format("YYYY-MM-DD")],
        user: 0,
        formattedDate: [],
        dateForDb: "",
        statusItems: [],
        userItems: [],
        menu: false,
        inputLabel: "",
        productName: "",
        defaultDate: [],
    }),
    props: {
        isUserAdmin: Boolean,
        isOrders: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.getStatus();
        this.setFiltersFromState();
        // this.filterArray();
        let currentDate = new Date();
        let firstDayOfCurrentMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );

        let currentDateStr = currentDate.toISOString().slice(0, 10);
        let firstDayOfCurrentMonthStr = firstDayOfCurrentMonth
            .toISOString()
            .slice(0, 10);

        this.defaultDate = [firstDayOfCurrentMonthStr, currentDateStr];
    },
    methods: {
        ...mapActions(["signout", "handleAlert", "handleSalesFilters"]),

        async getStatus() {
            try {
                const res = await getAllStatus(this.userToken);
                this.statusItems = res.data;
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },

        async getUsers() {
            try {
                const res = await getAllUsers(this.userToken);
                this.userItems = [];
                res.data.forEach((u) =>
                    this.userItems.push({
                        id: u.id,
                        name: u.name + " " + u.last_name,
                    })
                );
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },

        handleMessageErrorApi(error) {
            this.alertColor = "error";
            if (error.status === 401) {
                this.signout();
                this.alertMessage = "Sesión caducada";
            } else {
                if (error.status === 422) {
                    const firstError = Object.keys(error.data.errors)[0];
                    this.alertMessage = error.data.errors[firstError][0];
                } else {
                    this.alertMessage = error.status;
                }
            }
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },

        filterArray() {
            if (
                this.status === 0 &&
                Array.isArray(this.date) &&
                this.date.length === 0 &&
                this.user === 0 &&
                this.productName === ""
            ) {
            } else {
                this.handleSalesFilters({
                    status: this.status,
                    date: this.date.length >= 1 ? this.date : this.defaultDate,
                    user: this.user,
                });
                this.$emit("filter", this.date, this.productName);
            }
        },

        setFiltersFromState() {
            const { date, status, user } = this.getSalesFilters;
            this.date = date !== "" ? date : [];
            this.status = status;
            this.user = user;
        },

        clearFilters() {
            if (this.status !== 0 || this.date.length > 1 || this.user !== 0) {
                this.date = [];
                this.status = "";
                this.user = "";
                this.productName = "";
                this.handleSalesFilters({ status: "", date: "", user: "" });
                this.$emit("clearFilters");
            } else {
            }
        },

        formatDates(datesArray) {
            if (datesArray[0] < datesArray[1]) {
                this.dateForDb =
                    moment(datesArray[0]).format("YYYY-MM-DD") +
                    " - " +
                    moment(datesArray[1]).format("YYYY-MM-DD");
                this.formattedDate =
                    moment(datesArray[0]).format("DD/MM/YYYY") +
                    " - " +
                    moment(datesArray[1]).format("DD/MM/YYYY");
            } else {
                this.dateForDb =
                    moment(datesArray[1]).format("YYYY-MM-DD") +
                    " - " +
                    moment(datesArray[0]).format("YYYY-MM-DD");
                this.formattedDate =
                    moment(datesArray[1]).format("DD/MM/YYYY") +
                    " - " +
                    moment(datesArray[0]).format("DD/MM/YYYY");
            }
            return this.formattedDate;
        },
        setDefaultDateRange() {
            const currentDate = new Date();
            const currentMonthStartDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );
            const currentMonthEndDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );
            this.date = [currentMonthStartDate, currentMonthEndDate];
        },
    },
    computed: {
        ...mapState(["userToken"]),
        ...mapGetters(["getSalesFilters"]),

        dateRangeText() {
            return this.date.length > 1 ? this.formatDates(this.date) : "";
        },
    },
    watch: {
        isUserAdmin: {
            handler() {
                if (this.isUserAdmin === true) {
                    this.getUsers();
                }
            },
        },
    },
};
</script>

<style scoped lang="scss">
.v-card {
    background-color: $bgBody !important;
}
</style>
