<template>
<div>
  <v-data-footer v-if="!isSales"
    :pagination="pagination"
    :options="options"
    :items-per-page-options="[30]"
    @update:options="updateOptions"
  />
  <v-data-footer v-else
    class="salesTableFooter"
    :pagination="pagination"
    :options="options"
    :items-per-page-options="[30]"
    @update:options="updateOptions"
  />
</div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    options: Object,
    pagination: Object,
    isSales: Boolean
  },
  methods: {
    updateOptions (val) {
      this.$emit('updateOptions', val)
    }
  }
}

</script>

<style lang="scss" scoped>
.v-data-footer {
  justify-content: flex-end !important;
}
</style>
