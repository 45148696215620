<template>
  <div id="downloadSalesDiv">
    <v-btn color="primary" @click="getAllSales">Descargar Ventas</v-btn>
  </div>
</template>
<script>
import { excelParser } from '@/helpers/excel-parser.js'
import moment from 'moment'
import { getSalesComplete } from '../../services/sales'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DownloadComponent',
  props: {
    dataTable: Array
  },
  data () {
    return {
      allSales: []
    }
  },
  computed: {
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading']),
    async getAllSales () {
      try {
        this.handleLoading(true)
        const res = await getSalesComplete(this.userToken)
        this.allSales = await this.formatDataTable(res.data)
        this.downloadSales()
      } catch (error) {
        console.error('error', error)
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    },
    formatDataTable (data) {
      if (Array.isArray(data)) {
        data.forEach(e => {
          this.assignData(e)
        })
      } else {
        this.assignData(data)
      }
      return data
    },
    assignData (e) {
      e.user = e.user?.name + ' ' + e.user?.last_name
      e.payment_method = e.payment_method.name
      e.total_amount = '$' + e.total_amount
      e.applyBonification = e.bonification_percentage !== 0
      e.date_created = e.created_at.substr(0, 10)
      e.date_created = moment(e.created_at).format('LL')
      e.products.forEach((p) => {
        p.quantity = p.pivot.quantity
        p.price = p.actual_price
        p.total_amount = p.pivot.quantity * p.actual_price
      })
    },
    downloadSales () {
      // formatea ventas y productos por venta para parsear a excel (xls)
      const newArr = []
      this.allSales.forEach(i => {
        newArr.push({
          id_venta: i.id,
          usuario: i.user,
          id_usuario: i.user_id,
          porcentaje_bonificado: i.bonification_percentage,
          precio_total: i.total_amount,
          payment_method: i.payment_method,
          fecha_creacion: moment(i.created_at).format('DD/MM/YYYY'),
          fecha_modificacion: moment(i.updated_at).format('DD/MM/YYYY')
        })

        i.products.forEach(p => {
          newArr.push({
            id_venta: i.id,
            id: p.id,
            name: p.name,
            description: p.description,
            precio_unitario: '$' + p.price,
            cantidad: p.quantity
          })
        })
      })
      const orderedArray = newArr.sort(function (a, b) {
        return a.id_venta - b.id_venta
      })
      // Transforma json a otro formato y lo descarga. (data, fileExportType(xls por defecto))
      excelParser().exportDataFromJSON(orderedArray, null)
    }
  }
}
</script>

<style>
#downloadSalesDiv {
  padding-bottom: 2em !important;
}
</style>
