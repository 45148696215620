import exportFromJSON from 'export-from-json'
import moment from 'moment'

export const excelParser = () => {
  function exportDataFromJSON (data, fileExportType) {
    if (!data) return
    try {
      const fileName = 'listado-ventas-etiquecosas_' + moment().format('DD-MM-YYYY')
      const exportType = exportFromJSON.types[fileExportType || 'xls']
      exportFromJSON({ data, fileName, exportType })
    } catch (e) {
      throw new Error('Parsing failed!')
    }
  }

  return {
    exportDataFromJSON
  }
}
